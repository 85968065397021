/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"

import HeaderTwo from './../component/header/HeaderTwo.jsx'
import "./layout.css"
import FooterTwo from "../component/footer/FooterTwo.jsx"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allContentfulSocialMediaAccount {
        nodes {
          url
          socialSite
        }
      }

      allContentfulJournalEntry(
        limit: 6
        sort: { fields: createdAt, order: DESC }
      ) {
        nodes {
          categoryGroup {
            category
          }
          title
          url
          mainImage {
            fixed(width: 390, height: 590, cropFocus: CENTER) {
              src
            }
          }
        }
      }
    }
  `)

  const { allContentfulSocialMediaAccount, allContentfulJournalEntry } = data

  const socialMedia = allContentfulSocialMediaAccount.nodes

  const journalsPresent = allContentfulJournalEntry.nodes.length > 0

  return (
    <>
      <div>
        <HeaderTwo socialMedia={socialMedia} journalsPresent={journalsPresent} />
        <main>{children}</main>
        <footer>
          <FooterTwo socialMedia={socialMedia} />
          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
