import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import PageHelmet from "../component/common/Helmet"
import ServiceDetails from './ServiceDetails'
import Breadcrumb from "./common/Breadcrumb"
import Footer from "../component/footer/Footer"
import {
  FiWatch,
  FiTrendingUp,
  FiUsers,
  FiActivity,
  FiBarChart,
  FiGrid,
} from "react-icons/fi"

const contentOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => (
      <>
        <span>{text}</span>
      </>
    ),
  },

  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="tilte">{children}</h2>
    ),
  },
}

class LeadershipConsulting extends Component {
  render() {

    const { content, headerImage, sections, title} = this.props.data.contentfulPage

    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={title} />
        {/* End Breadcrump Area */}

        {/* Begin Banner */}
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
            style={{
              backgroundImage: `url("${headerImage.fluid.src}")`,
              backgroundSize: "cover",
              backgroundPosition: "5% 50%",
              height: "700px",
            }}
          />
        </div>
        {/* End Slider Area   */}

        {/* End Banner */}

        {/* Start Content Area */}
        <div className="rn-columns-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-column">
                 { documentToReactComponents(content.json, contentOptions)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start 
        {/* End Content Area */}

        {/*Service Explanation Start*/}
          <ServiceDetails data={sections} />
        {/*Service Explanation End*/}

        <Footer />
      </React.Fragment>
    )
  }
}

export default LeadershipConsulting
