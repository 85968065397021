import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../index.scss"
import ExecutiveCoaching from "../elements/ExecutiveConsulting"

const IndexPage = props => (
  <Layout>
    <SEO
      title="Executive Coaching"
      image={props.data.contentfulPage.headerImage.fluid.src}
      description={props.data.contentfulPage.description.description}
      lang="english"
    />
    <ExecutiveCoaching data={props.data} />
  </Layout>
)


export const pageQuery = graphql`
         query {
           contentfulPage(url: { eq: "executive-coaching" }) {
             id
             description {
               description
             }
             content {
               json
             }
             headerImage {
               fluid {
                 src
               }
             }
             sections {
               sectionContent {
                 json
               }
               title
               sectionImage {
                 fluid {
                   src
                 }
               }
             }
             title
           }
         }
       `
export default IndexPage
