import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const contentOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => <p style={{ fontStyle: "italic" }}>{text}</p>,
  },

  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="title">{children}</h2>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="title">{children}</h4>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="liststyle">{children}</ul>
    ),
  },
}

const RightAligned = ({sectionContent, sectionImage, title}) => {
  return (
    <div className="row sercice-details-content pb--80 align-items-center">
      <div className="col-lg-6 col-12">
        <div className="thumb">
          <img
            className="w-100"
            src={sectionImage.fluid.src}
            alt={title}
          />
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <h2 className="title">{title}</h2>
        <div className="details mt_md--30 mt_sm--30">{documentToReactComponents(sectionContent.json, contentOptions)}</div>
      </div>
    </div>
  )
}

const LeftAligned = ({ sectionContent, sectionImage, title }) => {
  return (
    <div className="row sercice-details-content pb--80 align-items-center">
      <div className="col-lg-6 col-12">
        <div className="details mt_md--30 mt_sm--30">
          <h2 className="title">{title}</h2>
          {documentToReactComponents(sectionContent.json, contentOptions)}
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <div className="thumb">
          <img className="w-100" src={sectionImage.fluid.src} alt={title} />
        </div>
      </div>
    </div>
  )
}

class ServiceDetails extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    { this.props.data.map( (section, id) => {
                        const Content = id % 2 === 0 ? (
                          <RightAligned
                            key={id}
                            sectionImage={section.sectionImage}
                            sectionContent={section.sectionContent}
                            title={section.title}
                          />
                        ) : (
                          <LeftAligned
                            key={id}
                            sectionImage={section.sectionImage}
                            sectionContent={section.sectionContent}
                            title={section.title}
                          />
                        )

                        return Content
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}
      </React.Fragment>
    )
  }
}
export default ServiceDetails
